
































































import {Component, Vue, Watch} from "vue-property-decorator";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import PayrollService from "@/services/payroll/PayrollService";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import FileService from "@/services/FileService";
import {namespace} from "vuex-class";
import FileUpload from "@/components/common/FileUpload.vue";
import Workspace from "@/dto/auth/Workspace";
import PayrollReportFilterOld, {STATE_CATEGORY} from "@/dto/payroll/PayrollReportFilterOld";
import PayrollReportDTO from "@/dto/payroll/PayrollReportDTO";
import PayrollDocumentActionModal from "@/components/util/DocumentActionModal.vue";
import Workspaces from "@/state/Workspaces";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component({
  components: {FileUpload}
})
export default class BusinessStateReports extends Vue {

  @AppModule.State
  private loading!: boolean;

  @AppModule.Action
  private startLoading!: () => void;

  @AppModule.Action
  private stopLoading!: () => void;

  @Auth.Getter("hasAdministrativePrivileges")
  private hasAdministrativePrivileges!: boolean;

  private message = "";

  private successful = true;

  private reports: Array<PayrollReportDTO> = [];

  private periods: Array<string> = [];

  private filter = new PayrollReportFilterOld();

  mounted(){
    this.filter.companyId = Workspaces.getCurrent.id;
    this.filter.category = STATE_CATEGORY;
    if(Workspaces.getCurrent.id == Number.parseInt(this.$route.params.companyId, 10)) {
      this.loadReportsAndPeriods();
    }
  }

  onFilesUploaded(files: FileMetaDTO[], employerId: number, period: string){
    this.startLoading();
    this.message = "";
    PayrollService.batchUploadReports(Workspaces.getCurrent.id, files.map(f => f.id), STATE_CATEGORY)
        .then(() => {this.loadReportsAndPeriods();})
        .then(() => {this.stopLoading();})
  }

  loadPeriods(){
    this.startLoading();
    this.message = "";
    return PayrollService.getReportPeriods(Workspaces.getCurrent.id, STATE_CATEGORY).then(
        response => {
          this.periods = response.data.sort().reverse();
          this.filter.period = this.periods[0];
          this.successful = true;
        },
        error => {
          this.successful = false;
          this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
        }
    ).then(() => {
      this.stopLoading();
    });
  }

  loadReports(){
    this.startLoading();
    this.message = "";
    return PayrollService.getReports(this.filter).then(
        response => {
          this.reports = response.data;
          this.successful = true;
        },
        error => {
          this.successful = false;
          this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
        }
    ).then(() => {
      this.stopLoading();
    })

  }

  loadReportsAndPeriods(){
    this.loadPeriods().then(() => {this.loadReports();});
  }


  @Watch('workspace')
  onWorkspaceChanged(n: Workspace, o: Workspace) {
    if (n.id == Number.parseInt(this.$route.params.companyId, 10)) {
      this.filter.companyId = Workspaces.getCurrent.id;
      this.loadReportsAndPeriods();
    }
  }

  onDocumentClick(document: PayrollReportDTO) {
    if (this.hasAdministrativePrivileges) {
      this.showActions(document);
    } else {
        Vue.prototype.$docUtils.download(document.file.id);
    }
  }

  showActions(document: PayrollReportDTO) {
    this.$modal.show(
        PayrollDocumentActionModal,
        {
          onDelete: () => this.deleteDocument(document),
          onDownload: () => Vue.prototype.$docUtils.download(document.file.id)
        },
        {width: "50%", height: "auto"}
    );
  }

  deleteDocument(document: PayrollReportDTO) {
    this.message = "";
    this.startLoading();
    PayrollService.deleteDocument(document.id, 'PAYROLL_REPORT').then(
        response => {
          this.message = "Deleted";
          this.successful = true;
          this.stopLoading();
          this.loadReports();
        },
        error => {
          this.successful = false;
          this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
          this.stopLoading();
        }
    );
  }

}
